function extractColors(hex: string): { r: number; g: number; b: number } {
  if (hex.length === 4) {
    // eslint-disable-next-line no-param-reassign
    hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b };
}

export default extractColors;
